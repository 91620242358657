import React, { Component } from "react";
import Logo from "../images/logo-sm.png";
// import enImg from '../images/en.png'
// import thImg from '../images/th.png'
import IconSignout from "../images/icons/icon-sign-out.png";
import { connect } from "react-redux";
import { Icon, Popover, Button, message } from "antd";
import "./Header.css";
import { setLanguage } from "../redux/actions/language";
import { setToken } from "../redux/actions/token";
import { Link } from "react-router-dom";
import {
  getAirport,
  getAirline,
  getAirTypeOnly,
  getAirTypeInSetting,
  getFilterAirtypeCode,
  getFlightStatus,
  getApproveStatus,
  //getFlightNo,
  getCallsign,
  getCallsignOnly,
  getArrDep,
  getAirportDOA
} from "../redux/actions/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Header extends Component {
  state = {
    disable: false,
    mr: "mr-2",
    checkData: 10
  };

  handleClick = ({ key }) => {
    const { language } = this.props;
    if (key !== language) {
      this.props.setLanguage(key);
    }
  };

  onRefresh = () => {
    let count = 0;
    const { checkData } = this.state;
    this.setState({ disable: true, mr: "mr-2 fa-spin" });
    // this.props.getAirport().then(x => {
    //   count = count + 1;
    //   if (count === 22) {
    //     this.setState({ disable: false, mr: "mr-2" });
    //     return message.success("โหลดข้อมูล Filter เรียบร้อย");
    //   }

    // });
    this.props.getAirline().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
    this.props.getFilterAirtypeCode().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
    this.props.getAirTypeInSetting().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
    this.props.getAirTypeOnly().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
    this.props.getFlightStatus().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
    this.props.getApproveStatus().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
    this.props.getCallsign().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
    this.props.getCallsignOnly().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
    this.props.getArrDep().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
    this.props.getAirportDOA().then(res => {
      count = count + 1;
      if (count === checkData) {
        this.setState({ disable: false, mr: "mr-2" });
        return message.success("โหลดข้อมูล Filter เรียบร้อย");
      }
    });
  };

  logout = () => {
    this.props.setToken("");
    sessionStorage.clear();
    window.oidc.signoutRedirect()
  };

  render() {
    const { language, token, permission } = this.props;
    //const name = context[language].header;
    // const menu = (
    //   <Menu onClick={this.handleClick}>
    //     <Menu.Item key="th" className="header-flag">
    //       <img className="img" src={thImg} alt="flag" /> TH
    //     </Menu.Item>
    //     <Menu.Item key="en" className="header-flag">
    //       <img className="img" src={enImg} alt="flag" /> EN
    //     </Menu.Item>
    //   </Menu>
    // )
    // const activeImg = language === 'th' ? thImg : enImg
    const logoutText = language === "th" ? "ออกจากระบบ" : "Logout";
    const loginText = language === "th" ? "เข้าสู่ระบบ" : "Login";
    return (
      <header className="row align-items-center">
        <div className="col">
          <div className="d-flex align-items-center">
            <Link to="/">
              <img src={Logo} alt="Logo" className="img-logo" />
            </Link>
            {/* <h3 className="mb-0">{name && name.app}</h3> */}
            <div className="header-text">
              <h3 className="text-white" style={{ fontSize: 23 }}>
                Air Transport Statistics System
              </h3>
              <h4 className="text-white" style={{ fontSize: 20 }}>
                ระบบจัดการข้อมูลสถิติการขนส่งทางอากาศ
              </h4>
            </div>
            {/* <Dropdown className="flag-container" overlay={menu} trigger={['click']}>
              <div className="ant-dropdown-link" href="#">
                <img className="img" src={activeImg} alt="flag" /> {language.toUpperCase()}
                <Icon className="down" type="down" />
              </div>
            </Dropdown> */}
          </div>
        </div>
        {token && (
          <div className="col-auto">
            <div className="d-flex align-items-center">
              {/* <img src={ImgUser} alt="ImgUser" className="img-user" /> */}
              <Button
                className="mr-4"
                onClick={this.onRefresh}
                disabled={this.state.disable}
              >
                <FontAwesomeIcon className={this.state.mr} icon="redo" />
                Refresh Data
              </Button>
              <span className="name-user">
                {permission && (permission.fullName || "-")}
              </span>
              <Popover
                placement="bottomLeft"
                content={
                  <div>
                    <div>
                      <Button className="container-fluid" onClick={this.logout}>
                        {logoutText}
                      </Button>
                    </div>
                    <div className="mt-2">
                      <Link to="/changepassword">
                        <Button>Change Password</Button>
                      </Link>
                    </div>
                  </div>
                }
                trigger="click"
              >
                <img
                  title={logoutText}
                  src={IconSignout}
                  alt="IconSignout"
                  className="img-link"
                />
              </Popover>
            </div>
          </div>
        )}
        {!token && <div className="col" />}
        {!token && (
          <div className="col-auto">
            <Link style={{ color: "#fff" }} to="/login">
              <div className="d-flex align-items-center">
                <span className="name-user">{loginText}</span>
                <Icon type="login" />
              </div>
            </Link>
          </div>
        )}
      </header>
    );
  }
}

const mapStateToProps = state => ({
  token: state.token,
  language: state.language,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  setLanguage: lang => dispatch(setLanguage(lang)),
  setToken: token => dispatch(setToken(token)),
  getAirport: () => dispatch(getAirport()),
  getAirline: () => dispatch(getAirline()),
  getAirTypeOnly: () => dispatch(getAirTypeOnly()),
  getAirTypeInSetting: () => dispatch(getAirTypeInSetting()),
  getFilterAirtypeCode: () => dispatch(getFilterAirtypeCode()),
  getFlightStatus: () => dispatch(getFlightStatus()),
  getApproveStatus: () => dispatch(getApproveStatus()),
  //getFlightNo: () => dispatch(getFlightNo()),
  getCallsign: () => dispatch(getCallsign()),
  getCallsignOnly: () => dispatch(getCallsignOnly()),
  getArrDep: () => dispatch(getArrDep()),
  getAirportDOA: () => dispatch(getAirportDOA())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
