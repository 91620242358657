import React, { Fragment, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Alert, Button } from 'antd'

const RenewkPage =() => {
  const handleRenewSilent = async () => {
    try {
      // const resp = await window.oidc.signinSilentCallback()
      // if (resp) {
      // }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleRenewSilent()
  }, [])

  return null
}

const CallBackPage = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const checkCallback = async () => {
    try {
      const resp = await window.oidc.signinRedirectCallback()
      if (resp) {
        window.location.href = '/'
      }
    } catch (error) {
      setLoading(false)
      setError(error.message)
      console.log(error)
    }
  }

  useEffect(() => {
    checkCallback()
  }, [])

  return (
      <div className="container pt-5">
        <div className="row align-items-center">
          <div className="col-12 col-lg-12" style={{ height: '500px' }}>
            <div className="d-flex justify-content-center align-items-center h-100">
              {
                loading && <div className="spinner"></div>
              }
              {
                error && 
                <div className="text-center">
                  <div>
                    <Alert message="ไม่สามารถเชื่อมต่อระบบสถิติได้" type="error" showIcon />
                  </div>
                  <br/>
                  <div>
                    <Button onClick={() => window.oidc.signinRedirect()}>
                      กลับหน้า Login
                    </Button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
  )
}

const CallBackRouter = ({ onLoginDone }) => (
  <Router>
    <Fragment>
        <Switch>
          <Route path="/silent-renew" exact component={() => <RenewkPage />} />
          <Route path="/callback" exact component={() => <CallBackPage />} />
        </Switch>
    </Fragment>
  </Router>
);


export default CallBackRouter