const pages = [
  { menu: "", text: "Flight Schedule" },
  { menu: "flightdetail", text: "Flight Detail / Flight Status" },
  { menu: "flightschedule", text: "Flight Schedule Manage" },
  { menu: "flightslot", text: "Flight Slot" },
  { menu: "counterslot", text: "Counter Chk-in / Belt Slot" },

  { menu: "", text: "Air Transport Statistics" },
  { menu: "statistics", text: "Air Transport Statistics" },

  { menu: "", text: "Operation Report" },
  { menu: "operationreport", text: "Operation Report" },

  { menu: "formmanagement", text: "Form Management" },

  { menu: "", text: "User Permission" },
  { menu: "department", text: "Department of Airports" },
  // { menu: "airline", text: "Airlines" },

  { menu: "", text: "Airline Setting" },
  { menu: "relationsetting", text: "Relation" },
  { menu: "airlinesetting", text: "Airline" },
  { menu: "callsignsetting", text: "Callsign" },
  { menu: "airtypesetting", text: "Air Type" },

  { menu: "", text: "Airport Setting" },
  { menu: "airportsetting", text: "Airports" },
  { menu: "airporttypesetting", text: "Airport Type" },
  { menu: "zonenosetting", text: "Zone No." },
  { menu: "regionsetting", text: "Region No." },
  { menu: "terminalsetting", text: "Tarminal" },
  { menu: "beltsetting", text: "Belt" },
  { menu: "gatesetting", text: "Gate" },
  { menu: "parkingbaysetting", text: "Parking Bay" },
  { menu: "parkingslotsetting", text: "Parking Slot" },

  { menu: "", text: "Flight Setting" },
  { menu: "flightstatussetting", text: "Flight Status" },
  { menu: "trafftypesetting", text: "Traff Type" },
  { menu: "countersetting", text: "Counter Chk-in / Belt" },

  { menu: "", text: "Calculation of Service Setting" },
  { menu: "landingsetting", text: "Landing" },
  { menu: "parkingsetting", text: "Parking" },
  { menu: "pscsetting", text: "PSC" },
  { menu: "appssetting", text: "APPS" },

  { menu: "", text: "Dashboard (Power BI)" },
  { menu: "dashboard", text: "Dashboard (Power BI)" }
];

module.exports = { pages };
