import React, { Fragment, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom"
import Home from './Home'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/NavbarMenu'

import { isAccessable, isAccessableMenu } from '../utils/permission'

import Statistics from './Statistics'
import FlightDetail from './FlightDetail'

import FlightSchedule from './FlightSchedule'
import FlightScheduleDetail from './FlightSchedule/FlightDetail'
import FlightCancel from './FlightSchedule/FlightCancel'
import FlightEdit from './FlightSchedule/FlightEdit'

import OperationReport from './OperationReport'
import FlightSlot from './FlightSlot'
import CounterSlot from './CounterSlot'

import FormManagement from './FormManagement'
import FormEdit from './FormManagement/FormEdit'
import FormView from './FormManagement/FormView'

import UserDepartment from './UserDepartment'
import AddGroup from './UserDepartment/AddGroup'
import ViewUser from './UserDepartment/ViewUser'
import AddUser from './UserDepartment/AddUser'
import UserAirline from './UserAirline'
import CreateAdminAccount from './UserAirline/CreateAdminAccount'
import ViewUserAirline from './UserAirline/ViewUser'

import SettingAirline from './SettingAirline'
import SettingAirport from './SettingAirport'
import SettingFlight from './SettingFlight'
import SettingCalculation from './SettingCalculation'
import FormulaViewAll from './SettingCalculation/Formula/FormulaViewAll'

import Dashboard from './Dashboard'

import ChangePassword from './ChangePassword'

const Nav = withRouter(Navbar)
const AppRouter = () => (
  <Router>
    <Fragment>
      <div className="container-fluid container-content">
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Fragment>
            <Nav />
            <Route path="/changepassword" component={ChangePassword} />
            {isAccessable('statistics') && <Route path="/statistics" component={Statistics} />}
            {isAccessable('flightdetail') && <Route path="/flightdetail" component={FlightDetail} />}
            {isAccessable('flightschedule') && <Route exact path="/flightschedule" component={FlightSchedule} />}
            {isAccessable('flightschedule') && <Route exact path="/flightschedule/detail/:id" component={FlightScheduleDetail} />}
            {isAccessable('flightschedule') && <Route exact path="/flightschedule/detail/cancel/:id/:status/:dateid" component={FlightCancel} />}
            {isAccessable('flightschedule') && <Route exact path="/flightschedule/edit/:id?" component={FlightEdit} />}
            {isAccessable('flightschedule') && <Route exact path="/flightschedule/add" component={FlightEdit} />}
            {isAccessable('operationreport') && <Route path="/operationreport" component={OperationReport} />}
            {isAccessable('formmanagement') && <Route exact path="/formmanagement" component={FormManagement} />}
            {isAccessable('formmanagement') && <Route exact path="/formmanagement/edit/:id?" component={FormEdit} />}
            {isAccessable('formmanagement') && <Route exact path="/formmanagement/add" component={FormEdit} />}
            {isAccessable('formmanagement') && <Route exact path="/formmanagement/view/:id" component={FormView} />}
            {isAccessable('flightslot') && <Route path="/flightslot" component={FlightSlot} />}
            {isAccessable('counterslot') && <Route path="/counterslot" component={CounterSlot} />}
            {isAccessableMenu('Airline Setting') && <Route exact path="/airlinesetting" component={SettingAirline} />}
            {isAccessableMenu('Airport Setting') && <Route exact path="/airportsetting" component={SettingAirport} />}
            {isAccessableMenu('Flight Setting') && <Route exact path="/flightstatussetting" component={SettingFlight} />}
            {isAccessableMenu('Calculation of Service Setting') && <Route exact path="/flightcalculationsetting" component={SettingCalculation} />}
            {isAccessableMenu('Calculation of Service Setting') && <Route exact path="/flightcalculationsetting/viewall/:id/:tid/:fid" component={FormulaViewAll} />}
            {isAccessable('department') && <Route exact path="/department" component={UserDepartment} />}
            {isAccessable('department') && <Route exact path="/department/addgroup" component={AddGroup} />}
            {isAccessable('department') && <Route exact path="/department/editgroup/:id" component={AddGroup} />}
            {isAccessable('department') && <Route exact path="/department/viewuser/:id" component={ViewUser} />}
            {isAccessable('department') && <Route exact path="/department/viewuser" component={ViewUser} />}
            {isAccessable('department') && <Route exact path="/department/viewuser/adduser/:id" component={AddUser} />}
            {isAccessable('department') && <Route exact path="/department/viewuser/edituser/:id/:uid" component={AddUser} />}
            {isAccessable('airline') && <Route exact path="/airline" component={UserAirline} />}
            {isAccessable('airline') && <Route exact path="/airline/createadmin/:id" component={CreateAdminAccount} />}
            {isAccessable('airline') && <Route exact path="/airline/viewuser/editadmin/:id/:uid?" component={CreateAdminAccount} />}
            {isAccessable('airline') && <Route exact path="/airline/viewuser/:id" component={ViewUserAirline} />}
            {isAccessable('dashboard') && <Route exact path="/dashboard" component={Dashboard} />}
          </Fragment>
        </Switch>
      </div>
      <Footer />
    </Fragment>
  </Router>
);

export default AppRouter