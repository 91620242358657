export const login = credentials => {
  const paramBody = {
    username: credentials.user,
    password: credentials.pass,
    grantType: "password"
  };
  var querystring = require("querystring");
  return {
    type: "LOGIN",
    payload: {
      request: {
        url: "/token",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(paramBody)
      }
    }
  };
};

export const getPermission = (userId) => {
  return {
    type: "LOGIN",
    payload: {
      request: {
        url: `/v1/getPermission?userId=${userId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: null
      }
    }
  };
};

export const bypassLogin = token => {
  /**
   * Example response json
  {
    "access_token": "",
    "expires_in": ,
    "token_type": "",
    "refresh_token": "",
    "scope": ""
  }
   */
  var querystring = require("querystring");
  return {
    type: "LOGIN",
    payload: {
      request: {
        url: "/tokens",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify({
          ssoJwt: token,
        })
      }
    }
  };
};