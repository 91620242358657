import React from "react";
import { Provider } from "react-redux";
import reducers from "./redux/reducers";
import { createStore, applyMiddleware } from "redux";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import { render } from "react-dom";
import "./index.css";
// import "react-calendar-timeline/lib/Timeline.css";
import "antd/dist/antd.css";
import "./scss/style.scss";
import "react-day-picker/lib/style.css";

import Main from "./containers/Main/Main";
import * as serviceWorker from "./serviceWorker";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, far, fab);

let timeout;
const clearProgress = () => {
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(() => {
    const p = document.getElementById("__progress__");
    if (p) p.parentNode.removeChild(p);
  }, 500);
};

const progress = perc => {
  try {
    const p = document.getElementById("__progress__");
    if (perc >= 100) clearProgress();
    if (perc === 1) clearProgress();
    if (p) p.parentNode.removeChild(p);
    var e = document.createElement("div");
    e.className = "ant-progress-line ant-progress-status-active";
    e.id = "__progress__";
    e.innerHTML = `<div class="ant-progress-bg" style="position:fixed; width: ${perc}%; height: 5px;"></div>`;
    document
      .querySelector("body")
      .insertBefore(e, document.getElementById("root"));
  } catch (e) {
    console.log(e);
  }
};

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  onUploadProgress: function(progressEvent) {},
  onDownloadProgress: function(progressEvent) {
    //let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
    try {
      const url = progressEvent.currentTarget.responseURL;
      if (
        url.split("/")[3] !== "filter" &&
        !url
          .split("/")
          .pop()
          .startsWith("filter")
      ) {
        const mockLength = 465907;
        let perc = Math.floor((progressEvent.loaded * 100) / mockLength);
        if (progressEvent.total === 0) clearProgress();
        progress(perc);
      }
    } catch (e) {
      console.log(e);
    }
  }
});

client.interceptors.request.use(async config => {
  const lng = localStorage.getItem("lng");
  const user = await window.oidc.getUser()
  config.headers["Authorization"] = "bearer " + user.access_token;
  config.headers["Lang"] = lng && lng.toUpperCase();
  try {
    const url = config.url;
    if (
      url.split("/")[3] !== "filter" &&
      !url
        .split("/")
        .pop()
        .startsWith("filter")
    ) {
      progress(1);
    }
  } catch (e) {
    console.log(e);
  }
  return config;
});

client.interceptors.response.use(
  resp => {
    return resp;
  },
  error => {
    const {
      response: { status }
    } = error;
    if (
      status === 401 &&
      window.location.pathname !== "" &&
      store.getState().token != null
    ) {
      // alert("Login expired.");
      // sessionStorage.clear();
      // window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

let store = createStore(reducers, applyMiddleware(axiosMiddleware(client)));

//console.log(store.getState());

serviceWorker.unregister(); // ย้ายมาจากด้านล่าง , 17/07/2019
render(
  <Provider store={store}>
    <Main />
  </Provider>,
  document.getElementById("root")
);
//serviceWorker.unregister(); // ย้ายไปอยู่ก่อน render , 17/07/2019
