import React, { Component } from "react";
import "./Main.css";
import { connect } from "react-redux";
import Router from "../Router";
import RouterAdminAirline from "../RouterAdminAirline";
import {
  getAirline,
  getAirTypeOnly,
  getAirTypeInSetting,
  getFlightStatus,
  getApproveStatus,
  getFilterAirtypeCode,
  getCallsign,
  getCallsignOnly,
  getArrDep,
  getAirportDOA
} from "../../redux/actions/dropdown";
import { message } from "antd";
import { setSearch } from "../../redux/actions/search";
import { setToken } from "../../redux/actions/token";
import { getPermission } from "../../redux/actions/login";
import { setPermission } from "../../redux/actions/permission";
import CallBackRouter from "../Login";

class Main extends Component {
  state = {
    isReady: false
  }
  
  componentDidMount() {
    let pathname = { pathname: "/" };
    this.props.setSearch(pathname);
    const isCallbackPage = window.location.pathname === '/callback' || window.location.pathname === '/callback/'
    if (!isCallbackPage) {
      this.checkPermission()
    }
    this.setState({
      isReady: true,
    })
  }

  checkPermission = async () => {
    const user = await window.oidc.getUser()
    if (!user || (user && user.expired)) {
      this.props.setToken("");
      sessionStorage.clear();
      window.oidc.signinRedirect()
      return
    }
    console.log('expired', user.expired)
    console.log('expires_in', user.expires_in)
    console.log('expires_in', window.location.pathname)
    this.props.setToken(user.access_token)
    if (window.location.pathname === '/silent-renew') {
      await window.oidc.signinSilentCallback()
      return
    }
    console.log(user.access_token)
    const resp = await this.props.getPermission(user.profile.userId)
    this.props.setPermission(resp.payload.data.data)
    this.setState({
      isReady: true
    })
    this.onLoginDone()
  }

  onLoginDone = () => {
    const { dropdown } = this.props;
    if (dropdown.airlineOptions.length === 0) this.props.getAirline();
    this.props.getAirTypeInSetting();
    this.props.getAirTypeOnly();
    if (dropdown.flightStatusOptions.length === 0) this.props.getFlightStatus();
    if (dropdown.approveStatusOptions.length === 0)
      this.props.getApproveStatus();
    this.props.getCallsign();
    this.props.getCallsignOnly();
    if (dropdown.arrDepOptions.length === 0) this.props.getArrDep();
    if (dropdown.airportDOAOptions.length === 0) this.props.getAirportDOA();
  };

  onError = error => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      message.error("Connection Error : " + JSON.stringify(error));
      this.props.setError(null);
    }, 1000);
  };

  render() {
    if (this.state.isReady) {
      const { permission } = this.props;
      if (permission && permission.isAdminAirline === 1)
        return (
          <>
            <CallBackRouter/>
            <RouterAdminAirline />;
          </>
      )
      return (
        <>
          <CallBackRouter/>
          {
            permission &&
            <Router />
          }
        </>
    );
    } else {
      return (
        <h1> Loading ... </h1>
      )
    }
  }
}

const mapStateToProps = state => ({
  dropdown: state.dropdown,
  language: state.language,
  token: state.token,
  error: state.error,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  setError: data => dispatch({ type: "REQUEST_ERROR", data }),
  // getAirport: () => dispatch(getAirport()),
  getAirline: () => dispatch(getAirline()),
  getAirTypeOnly: () => dispatch(getAirTypeOnly()),
  getAirTypeInSetting: () => dispatch(getAirTypeInSetting()),
  getFilterAirtypeCode: () => dispatch(getFilterAirtypeCode()),
  getFlightStatus: () => dispatch(getFlightStatus()),
  getApproveStatus: () => dispatch(getApproveStatus()),

  getCallsign: () => dispatch(getCallsign()),
  getCallsignOnly: () => dispatch(getCallsignOnly()),

  getArrDep: () => dispatch(getArrDep()),
  getAirportDOA: () => dispatch(getAirportDOA()),

  setSearch: item => dispatch(setSearch(item)),
  getPermission: (userId) => dispatch(getPermission(userId)),
  setPermission: (data) => dispatch(setPermission(data)),
  setToken: (tk) => dispatch(setToken(tk))
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
